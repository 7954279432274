import { Link } from "gatsby";
import React from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";

export default ({ blogPost }) => {
  // Fast escape
  if (!blogPost?.content?.general) {
    return <></>;
  }

  const general = blogPost.content.general;

  return (
    <div className="promotions__item">
      <div className="product-card product-card--compact">
        <div className="product-card__header">
          <Link to={general.detailPath} className="link link--plain">
            {Boolean(general.image?.url) && (
              <img
                src={addWidthParameter(general.image.url, 1000)}
                alt={general.title}
                className="product-card__header-media"
              />
            )}
          </Link>
        </div>
        <h3 className="product-card__title">
          <Link to={general.detailPath} className="link link--plain">
            {general.title}
          </Link>
        </h3>
        <div className="product-card__footer">
          <div className="product-card__cta">
            <Link to={general.detailPath}>Lees meer</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
