import { Link } from "gatsby";
import React from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";

export default ({ shippingCompany }) => {
  // Fast escape
  if (!shippingCompany?.company?.content?.general) {
    return <></>;
  }

  const company = shippingCompany.company;

  return (
    <div className="promotions__item">
      <div className="product-card product-card--compact">
        <div className="product-card__header">
          {Boolean(company?.content?.general?.image) && (
            <Link to={company?.content?.general?.detailPath ?? "/"} className="link link--plain">
              <img
                src={addWidthParameter(company?.content?.general?.image?.url, 1000)}
                alt={company?.content?.general?.title}
                className="product-card__header-media"
              />
            </Link>
          )}
        </div>
        <h3 className="product-card__title">
          <Link to={company?.content?.general?.detailPath ?? "/"} className="link link--plain">
            {company?.content?.general?.title}
          </Link>
        </h3>
        <div className="product-card__ship">{company?.content?.general?.description}</div>

        <div className="product-card__footer">
          <div className="product-card__cta">
            <Link to={company?.content?.general?.detailPath ?? "/"}>Meer info</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
