import BlogPost from "../components/dyanamic-lists/blogPost";
import CruiseType from "../components/dyanamic-lists/cruiseType";
import { Helmet } from "react-helmet";
import Layout from "./layout";
import React, { useContext } from "react";
import SailingArea from "../components/dyanamic-lists/sailingArea";
import SeoHeaders from "../components/seo/seo-headers";
import ShippingCompany from "../components/dyanamic-lists/shippingCompany";
import { websiteName } from "../settings";
import GlobalContext from "../contexts/global-provider";

const WildcardPage = ({
  pageContext: { page, shippingCompanies, sailingAreas, cruiseTypes, blogPosts, navigation, privacy },
  location,
}) => {

  const {setNavigation, setPrivacy, theme} = useContext(GlobalContext);
  setNavigation(navigation);
  setPrivacy(privacy);
  
  // Data

  const general = page?.content?.general;
  const layout = page?.content?.layout;
  const seo = page?.content?.seo;

  if (!general || !layout || !seo) {
    return <></>;
  }

  const pageTitle = seo?.title ?? page.content.general.title;

  if (theme === "thalassa") {
    cruiseTypes = cruiseTypes.filter(c => c.content.general.isThalassa);
  } else if (theme === "select-together") {
    cruiseTypes = cruiseTypes.filter(c => c.content.general.isSelectTogether);
  }
  // Output

  return (
    <Layout
      isTiny={Boolean(layout.isTiny)}
      headerImage={page.content.general.headerImage}
      headerText={page.content.general.headerText}
      isTinted={Boolean(layout.backgroundTinted)}
      showQsm={Boolean(layout.qsm)}
      cruiseTypes={cruiseTypes}
    >
      <SeoHeaders seo={seo} pageTitle={pageTitle} pagePath={location.pathname} siteName={websiteName[theme]} />
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div className="products">
        <div className="products__title">
          <h2>{general.title}</h2>
        </div>
        <div className="products__content">
          {Boolean(general.isShippingCompanies && shippingCompanies?.length) &&
            shippingCompanies.map((shippingCompany, index) => (
              <ShippingCompany shippingCompany={shippingCompany} key={index} />
            ))}
          {Boolean(general.isSailingAreas && sailingAreas?.length) &&
            sailingAreas.map((sailingArea, index) => <SailingArea sailingArea={sailingArea} key={index} />)}
          {Boolean(general.isCruiseTypes && cruiseTypes?.length) &&
            cruiseTypes.map((cruiseType, index) => <CruiseType cruiseType={cruiseType} key={index} />)}
          {Boolean(general.isBlogPost && blogPosts?.length) &&
            blogPosts.map((blogPost, index) => <BlogPost blogPost={blogPost} key={index} />)}
        </div>
      </div>
    </Layout>
  );
};

export default WildcardPage;
