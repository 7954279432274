import { add, startOfMonth } from "date-fns";

import { Link } from "gatsby";
import React, { useContext } from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";
import GlobalContext from "../../contexts/global-provider";
import { getMonths } from "../../utils/dateUtils";

const CruiseType = ({ cruiseType }) => {
  const { setSearchSelection } = useContext(GlobalContext);
  // Fast escape
  if (!cruiseType?.content?.general || !cruiseType?.content?.usps) {
    return <></>;
  }

  const general = cruiseType.content.general;
  const usps = cruiseType.content.usps;

  // Actions
  const handleClick = (cruiseType) => {
    setSearchSelection({
      selectedCruiseTypes: [cruiseType],
      selectedSailingAreas: [],
      selectedDates: getMonths(),
      selectedDurations: [],
    });
  };

  return (
    <div className="promotions__item">
      <div className="product-card product-card--compact">
        <div className="product-card__header">
          <Link
            to={`/products?cruiseType=${cruiseType.itemId}`}
            onClick={() => handleClick(cruiseType)}
            className="link link--plain"
          >
            {Boolean(general.image?.url) && (
              <img
                src={addWidthParameter(general.image.url, 1000)}
                alt={general.title}
                className="product-card__header-media"
              />
            )}
          </Link>
        </div>
        <h3 className="product-card__title">
          <Link
            to={`/products?cruiseType=${cruiseType.itemId}`}
            onClick={() => handleClick(cruiseType)}
            className="link link--plain"
          >
            {general.title}
          </Link>
        </h3>
        <div className="product-card__tags">
          {Boolean(usps?.usP1?.content?.general) && (
            <div className="product-card__tag">{usps.usP1.content.general.text}</div>
          )}
          {Boolean(usps?.usP2?.content?.general) && (
            <div className="product-card__tag">{usps.usP2.content.general.text}</div>
          )}
          {Boolean(usps?.usP3?.content?.general) && (
            <div className="product-card__tag">{usps.usP3.content.general.text}</div>
          )}
        </div>
        <div className="product-card__ship" dangerouslySetInnerHTML={{ __html: general.description }}></div>
        <div className="product-card__footer">
          <div className="product-card__cta">
            <Link
              to={`/products?cruiseType=${cruiseType.itemId}`}
              onClick={() => handleClick(cruiseType)}
            >
              Toon alle {cruiseType.content.general.title.toLowerCase()}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};



export default CruiseType;
